/* eslint-disable */
export default {
  form: {
    title: 'Formations',
    color: 'red',
    datas: [
      {
        date: '2001 - 2002',
        title: 'Baccalauréat SSI - Toulouse',
        detail: "Bac Scientifique Sciences de l'Ingénieur",
      },
      {
        date: '2002 - 2004',
        title: 'DUT SERECOM - Tarbes',
        detail: 'Services et Réseaux de Communication',
      },
      {
        date: '2004 - 2006',
        title: 'IUP INGEMEDIA - Toulon',
        detail: 'Sciences de l’Information et de la Communication',
      },
      {
        date: '2006 - 2007',
        title: 'Master 2 IET - Toulon',
        detail: 'Intelligence Économique et Territoriale',
      },
    ],
  },
  exp: {
    title: 'Expériences',
    color: 'orange',
    datas: [
      {
        date: '2007 - 2010',
        title: 'Virtual-IT - Toulouse <span class="attr">[CDI]</span>',
        detail: 'Chef de projet multimédia',
      },
      {
        date: '2011 - 2022',
        title: 'Microentreprise - Toulouse',
        detail: 'Développement web et programmation informatique',
      },
      {
        date: '2019 - 2022',
        title: 'DBA - Toulouse <span class="attr">[CDI]</span>',
        detail: 'Développement web',
      },
      {
        date: '2022 - 2024',
        title: 'Foxyz - Tarbes <span class="attr">[CDI]</span>',
        detail: 'Développement web',
      },
    ],
  },
  prod: {
    title: 'Projets réalisés',
    color: 'blue',
    datas: [
      {
        date: '2007',
        title: 'Virtual-IT',
        detail: 'Réalisation de la charte graphique de l\'entreprise<br>Conception du site Internet <span class="title">virtual-it.fr</span>',
        images: [],
      },
      {
        date: '2008',
        title: 'Force Ouvrière Airbus',
        detail: 'Conception du site Internet <span class="title">foairbus.fr</span><br>Réalisation d\'un CD-Rom interactif pour <span class="title">FO Airbus Toulouse</span>',
        images: [],
      },
      {
        date: '2009',
        title: 'Ineo-RHT',
        detail: 'Conception du site Internet <span class="title">ineo-rht.fr</span>',
        images: [],
      },
      {
        date: '2010',
        title: 'EDF R&D',
        detail: 'Réalisation de documentaires et d\'interviews vidéos sur Paris<br>Conception d\'animations vectorielles sur le compteur <span class="title"> Linky</span>',
        images: [],
      },
      {
        date: '2011 - 2012',
        title: 'Tigergrip',
        detail: 'Mise à jour du site Internet <a href="https://www.tigergrip.com" class="title" rel="nofollow noreferrer" target="_blank">tigergrip.com</a>',
        images: [],
      },
      {
        date: '2013',
        title: 'ERDF',
        detail: 'Conception de l\'interface pour l\'application de salon <span class="title">Sogrid</span>',
        images: [],
      },
      {
        date: '2013',
        title: 'Thales Avionics',
        detail: 'Conception graphique et aide au développement de l\'application de salon <span class="title">TopWings EFB solution</span>',
        images: [],
      },
      {
        date: '2014',
        title: 'DGAC',
        detail: 'Développement d\'un outil interactif de formation pour <span class="title">le Service Technique de l’Aviation Civile (STAC)</span>',
        images: [],
      },
      {
        date: '2014',
        title: 'Thales Aéroporté',
        detail: 'Réalisation d\'une application de salon pour borne tactile',
        images: [],
      },
      {
        date: '2014',
        title: 'ERDF',
        detail: 'Création d\'animations vectorielles sur le réseau de distribution',
        images: [],
      },
      {
        date: '2014 - 2015',
        title: 'CNES',
        detail: 'Aide à la conception de l\'application de salon <span class="title">Charte Internationale Espace et Catastrophes Majeures (Charte-MT)</span>',
        images: [],
      },
      {
        date: '2015',
        title: 'Airbus Defence & Space',
        detail: 'Conception du magazine interactif à usage de communication interne pour la mission <span class="title">JUICE</span>',
        images: [],
      },
      {
        date: '2015',
        title: 'Toul Immo Réalisation',
        detail: 'Réalisation de la charte graphique de l\'entreprise<br>Conception du site Internet <a href="https://www.toulimmo-realisation.fr" class="title" rel="nofollow noreferrer" target="_blank">toulimmo-realisation.fr</a>',
        images: [],
      },
      {
        date: '2016',
        title: 'Toul Immo Rénovation',
        detail: 'Création d\'outils de communication externe<br>Conception du site Internet <a href="https://www.toulimmo-renovation.fr" class="title" rel="nofollow noreferrer" target="_blank">toulimmo-renovation.fr</a>',
        images: [],
      },
      {
        date: '2016',
        title: 'Coved',
        detail: 'Conception de l\'application de salon <span class="title">Nodus & Bio Collect</span>',
        images: [],
      },
      {
        date: '2016',
        title: 'Lingocentric',
        detail: 'Intégration CSS de la charte graphique pour la plateforme de traduction <span class="title">TextBeans</span>',
        images: [],
      },
      {
        date: '2016 - 2017',
        title: 'Spherea Test & Services',
        detail: 'Conception de l\'application de salon <span class="title">U-TEST ®</span>',
        images: [],
      },
      {
        date: '2017',
        title: 'Méca Jet d\'Eau',
        detail: 'Refonte du site Internet <a href="https://www.mecajetdeau.com" class="title" rel="nofollow noreferrer" target="_blank">mecajetdeau.com</a>',
        images: [],
      },
      {
        date: '2017',
        title: 'Continental',
        detail: 'Conception de l\'application de formation <span class="title">Contact-Geste</span>',
      },
      {
        date: '2017 - 2018',
        title: 'Enedis',
        detail: 'Conception du site Internet servant à l\'administration de l\'application <span class="title">eBalisage</span>',
        images: [],
      },
      {
        date: '2018',
        title: 'Association Transmettre',
        detail: 'Réalisation des sites Internet de <span class="title">l\'école Transmettre</span> et de <span class="title">l\'Institut ISHES</span>',
        images: [],
      },
      {
        date: '2019',
        title: 'Enerbim',
        detail: 'Aide au développement d\'un prototype pour le métier du BIM <span class="title">CN-BIMES</span>',
        images: [],
      },
      {
        date: '2019',
        title: 'Angle Droit Avocats',
        detail: 'Intégration CSS de la charte graphique pour un site vitrine',
        images: [],
      },
      {
        date: '2020',
        title: 'LVS',
        detail: 'Amélioration de la charte graphique de la marque<br>Aide au développement du site Internet <a href="https://www.le-vestiaire-sechant.fr" class="title" rel="nofollow noreferrer" target="_blank">le-vestiaire-sechant.fr</a>',
        images: [],
      },
      {
        date: '2020',
        title: 'Mon Atelier Pro',
        detail: 'Réalisation de la charte graphique de la marque<br>Conception du site Internet <a href="https://www.monatelierpro.fr" class="title" rel="nofollow noreferrer" target="_blank">monatelierpro.fr</a>',
        images: [],
      },
      {
        date: '2021',
        title: 'Les Jardins de Jordi',
        detail: 'Aide à la réalisation du site Internet <a href="https://www.lesjardinsdejordi.com" class="title" rel="nofollow noreferrer" target="_blank">lesjardinsdejordi.com</a><br>Formation sur l\'utilisation de WordPress et de Sendinblue',
        images: [],
      },
      {
        date: '2020-2021',
        title: 'DBA Armoires',
        detail: 'Amélioration de la charte graphique de la marque<br>Refonte du site Internet <a href="https://www.dba-armoires.fr" class="title" rel="nofollow noreferrer" target="_blank">dba-armoires.fr</a>',
        images: [],
      },
      {
        date: '2020-2022',
        title: 'Armoire Plus',
        detail: 'Amélioration de la charte graphique de la marque<br>Aide au développement du site Internet <a href="https://www.armoireplus.fr" class="title" rel="nofollow noreferrer" target="_blank">armoireplus.fr</a>',
        images: [],
      },
      {
        date: '2022',
        title: 'Brigade Hocaré',
        detail: 'Conception de la charte graphique de la marque',
        images: [],
      },
      {
        date: '2022-2024',
        title: 'Foxyz',
        detail: 'Aide au développement de l\'ERP <span class="title">Foxyz</span><br>Refonte du site Internet <a href="https://foxyz.fr" class="title" rel="nofollow noreferrer" target="_blank">foxyz.fr</a>',
        images: [],
      },
    ],
  },
}
